var windowWidth, windowHeight, documentWidth, documentHeight, topScrollPosition;
var siteRoot = window.location.protocol + "//" + window.location.host;

jQuery(document).ready(function($){

    var body = $('body');

    function recalculateSizes() {
        windowWidth = $(window).width(); //retrieve current window width
        windowHeight = $(window).height(); //retrieve current window height
        documentWidth = $(document).width(); //retrieve current document width
        documentHeight = $(document).height(); //retrieve current document height
        topScrollPosition = $(window).scrollTop(); //retrieve the document scroll ToP position
    }
    recalculateSizes();

    // execute on resize but only once
    var doResize;
    $(window).on('resize', function() {

        clearTimeout(doResize);
        doResize = setTimeout(function() {

        }, 300);
    });

    var doScroll;
    $(window).on('scroll', function() {

        clearTimeout(doScroll);
        doScroll = setTimeout(function() {

        }, 300);
    });

    $( window ).on( "orientationchange", function( event ) {

    });

});


jQuery(window).on('load', function() {
    // deferImages();
});

// Prevents passive listeners in jQuery, used for Google PageSpeed
jQuery.event.special.touchstart = {
    setup: function( _, ns, handle ) {
        this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.touchmove = {
    setup: function( _, ns, handle ) {
        this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.wheel = {
    setup: function( _, ns, handle ){
        this.addEventListener("wheel", handle, { passive: true });
    }
};
jQuery.event.special.mousewheel = {
    setup: function( _, ns, handle ){
        this.addEventListener("mousewheel", handle, { passive: true });
    }
};